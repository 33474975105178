.header-map-suggest-container {
    justify-content: space-between;
}

.header-map-suggest-container>h2 {
    margin: 0;
}

.header-map {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.header-row {
    display: flex;
    flex-direction: row;
}

.header-container {
    overflow-y: auto;
    overflow-x: auto;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(135, 135, 135, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 10px 20px 20px 20px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  
  
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}