/* src/components/Sidebar.css */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: width 0.3s ease;
    z-index: 1000;
}

.sidebar.minimized {
    width: 50px;
}

.sidebar .menu-btn {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    padding: 10px;
    cursor: pointer;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 50px;
}

.sidebar ul li {
    padding: 10px 20px;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

/* src/pages/Home.css */
.home {
    display: flex;
}

.sidebar-minimized .content {
    margin-left: 50px;
}

.content {
    margin-left: 250px;
    padding: 20px;
    transition: margin-left 0.3s ease;
    height: calc(100vh - 40px);
}