.tables-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    flex: auto;
    overflow: auto;
    /* Adjust the gap as needed */
}

.tables-container>table {
    width: 100%;
    /* Adjust the height as needed */
    overflow-y: auto;
    display: block;
    height: 100%;
}