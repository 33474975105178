.flow-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    transition: margin-left 0.3s ease;
    height: calc(100vh - 40px);
        width: calc(100vw - 40px);
}

.functions {
    overflow-y: auto;
    flex: none;
    padding-right: 21px;
    width: 10%;
}

.card-group {
    margin-bottom: 20px;
}

.card-group h3 {
    font-size: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card:hover {
    background-color: #e9e9e9;
}

.card:drag {
    /* Styles applied when the card is being dragged */
    opacity: 0.5;
    /* Make the card semi-transparent */
    transform: scale(1.1);
    /* Slightly scale up the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add a subtle drop shadow */
}

.react-flow__node {
    background-color: #ddd;
    border-radius: 5px;
}

.error-container {
    width:10%;
}

.error-list {
    margin: 10px;
}
.error-list p {
    margin: 5px;
}
