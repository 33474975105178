.iframe-icon {
    width: 1em;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 25%;
    left: 25%;
    width: 50vw;
    height: 50vh;
    background-color: var(--left-area-background);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-sizing: border-box;
}

.modal-codeblock {
    height: 100%;
    margin: 0 !important;
    background-color: white;
}

.warning-banner {
    border: 1px solid #ff6a96;
    color: black;
    padding: 10px;
    border-radius: 5px;
    text-align: 'center';
}

.warning-banner>p {
    color: white;
}